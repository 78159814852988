<template>
  <v-card class="custom-card-bg-1 overflow-hidden" height="100%">
    <v-layout class="fill-height overflow-hidden py-3" column>
      <div class="pa-2" style="flex: none">
        <v-select
          v-model="time"
          :items="times"
          :label="label"
          :placeholder="placeholder ? placeholder : label"
          dense
          hide-details
          item-text="date"
          outlined
          return-object
          @change="changeTimeline"
        >
        </v-select>
      </div>
      <v-divider />
      <v-layout class="overflow-y-auto pa-2" column style="flex: 1">
        <div v-if="Object.keys(time).length" style="width: 100%">
          <v-layout
            v-for="(item, key) of time.statistics"
            :key="key"
            align-center
            class="pt-1 subtitle-2"
            style="height: 35px;"
            v-bind:style="{ color: item.color }"
          >
            <span class="ml-2">{{ item.label }}</span>
            <v-spacer />
            <div class="mr-2" v-html="item.area !== null ? transformArea(item.area.toFixed(3)) : ''"></div>
          </v-layout>
        </div>
        <div class="pt-3" style="width: 100%; flex: none; text-align: center">
          INFORMATION:
        </div>
        <v-layout v-show="Object.keys(time).length" class="pt-3" justify-center>
          <canvas :id="time.date" height="135" width="135"></canvas>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-card>
</template>

<script>
import MonthPicker from '@/components/MonthPicker.vue'
import AreaConvert from '@/utils/textArea'
import Chart from 'chart.js/auto'
import sleep from '@/utils/sleep'

export default {
  name: 'CardDataOfMonth',
  components: { MonthPicker },
  data() {
    return {
      chartData: [],
      labels: [],
      chart: undefined,
    }
  },
  watch: {
    time(val) {
      if (!Object.keys(val).length) return
      if (!this.chart) {
        this.displayChart(this.time)
      } else {
        this.transformData()
        this.updateChartData(this.chartData)
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    times: {
      type: Array,
      default: () => [],
    },
    date: {
      type: Object,
      default: () => {},
    },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },
  computed: {
    time: {
      get() {
        return this.date
      },
      set(val) {
        this.$emit('update:date', val)
      },
    },
  },
  methods: {
    changeTimeline() {
      this.$emit('onChange')
    },
    transformArea(area) {
      return AreaConvert.covertArea(area)
    },
    transformData() {
      this.chartData = []
      this.labels = []
      this.labels = this.time.statistics.map(val => val.label)
      this.chartData.push({
        label: this.time.statistics.date,
        data: this.time.statistics.map(val => AreaConvert.covertNumberArea(val.area, 'ha')),
        backgroundColor: this.time.statistics.map(val => val.color),
      })
    },
    async displayChart() {
      this.transformData()
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      await sleep(250)
      let ctx = document.getElementById(this.time.date)
      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          title: {
            display: true,
            text: '',
          },
          scales: {},
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData) {
      this.chart.data.datasets = newData
      this.chart.data.labels = this.labels
      this.chart.update()
    },
  },
}
</script>

<style scoped></style>
