import api from '@/api/api'
import config from '@/config'

export async function getClassificationImages(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/dashboard/classification`,
    data.payload,
  )
}

export async function getChartData(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/charts/classification`,
    data.payload,
  )
}

export async function getClassificationLayers(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/map/classification`, data.payload)
}
