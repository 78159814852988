<template>
  <v-card class="custom-card-bg-0 overflow-hidden" height="100%">
    <v-layout ref="compareData" class="fill-height overflow-hidden custom-card-bg-1" column>
      <div class="pa-3" style="width: 100%; height: fit-content; flex: none">
        <v-layout align-center style="height: 100%">
          <div class="subtitle-1">Compare change between</div>
          <v-spacer />
          <div
            v-show="Object.keys(compareDate).length && Object.keys(currentDate).length"
            class="pl-2"
            style="color: #ff5900; text-transform: uppercase; font-weight: 800; font-size: 16px;"
          >
            {{ currentDate.date }}
            <b style="color: #9a9a9a">&</b>
            {{ compareDate.date }}
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-3" icon x-small @click="fullScreen" v-bind="attrs" v-on="on">
                <v-icon size="18">{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-arrow-expand' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen' }}</span>
          </v-tooltip>
        </v-layout>
      </div>
      <v-divider />
      <v-layout
        v-if="Object.keys(compareDate).length && Object.keys(currentDate).length"
        class="pa-4 overflow-y-auto"
        column
      >
        <v-row v-for="(item, index) of currentDate.statistics" :key="index">
          <v-col class="py-1" cols="12" md="5" xl="5">
            <v-layout column justify-center>
              <div>{{ item.label }} change in</div>
              <div class="pl-3" v-bind:style="{ color: item.color }">
                {{ item.label }} Area:
                <span
                  class="ml-2"
                  style="white-space: nowrap"
                  v-bind:style="{ color: areaChange(index) > 0 ? '#56CA00' : 'red' }"
                >
                  {{ Math.abs((areaChange(index) / area) * 100).toFixed(2) }}%
                  <v-icon :color="areaChange(index) > 0 ? 'success' : 'error'">
                    {{ areaChange(index) > 0 ? 'mdi-menu-up' : 'mdi-menu-down' }}
                  </v-icon>
                </span>
              </div>
            </v-layout>
          </v-col>
          <v-col cols="12" md="2" xl="2">
            <v-layout align-center class="fill-height">
              <div
                style="height: 18px; width: 100%; border-radius: 8px"
                v-bind:style="{ 'background-color': item.color }"
              ></div>
            </v-layout>
          </v-col>
          <v-col cols="12" md="5" xl="5">
            <v-layout align-center class="fill-height">
              <div>
                <span
                  v-bind:style="{ color: areaChange(index) > 0 ? '#56CA00' : 'red' }"
                  v-html="transformArea(index)"
                ></span>
                in
                <span v-bind:style="{ color: item.color }">{{ item.label }} Area</span>
              </div>
            </v-layout>
          </v-col>
        </v-row>
      </v-layout>
    </v-layout>
  </v-card>
</template>

<script>
import AreaConvert from '@/utils/textArea'
import ElemFullScreen from '@/utils/ElemFullScreen'

export default {
  name: 'CardCompareChange',
  data() {
    return {
      isFullscreen: false,
    }
  },
  computed: {},
  props: {
    area: {
      type: Number,
      default: 1,
    },
    currentDate: {
      type: Object,
      default: () => {},
    },
    compareDate: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
    })
  },
  methods: {
    fullScreen() {
      ElemFullScreen.setFullscreenRef(this.$refs.compareData)
    },
    areaChange(index) {
      return this.currentDate.statistics[index].area - this.compareDate.statistics[index].area
    },
    transformArea(index) {
      return AreaConvert.covertArea(this.areaChange(index))
    },
  },
}
</script>

<style scoped></style>
