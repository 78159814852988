<template>
  <v-layout class="fill-height overflow-hidden" column>
    <div class="pt-1 px-2" style="width: 100%; flex: none; height: fit-content">
      <v-card class="custom-card-bg-0">
        <v-layout align-center class="fill-height">
          <v-col ref="AOI" class="pb-0 pl-0" cols="12" md="3">
            <select2
              v-model="currentAOI"
              :items="listAOI"
              dense
              hide-details
              item-text="name"
              item-value="uuid"
              label="Select AOI"
              outlined
              placeholder="AOI"
              return-object
              @change="getService"
            />
          </v-col>
          <v-col ref="service" class="pb-0" cols="12" md="3">
            <v-select
              v-model="service"
              :disabled="!currentAOI"
              :items="services"
              dense
              hide-details
              item-text="service_name"
              label="Select service"
              outlined
              placeholder="Service"
              return-object
            />
          </v-col>
          <v-col ref="source" class="pb-0" cols="12" md="3">
            <v-select
              v-model="source"
              :disabled="!Object.keys(service).length"
              :items="sources"
              dense
              hide-details
              item-text="name"
              label="Select source"
              outlined
              placeholder="Source"
              return-object
              @change="getListData"
            />
          </v-col>
          <v-col class="pb-0" cols="12" md="3"></v-col>
        </v-layout>
      </v-card>
    </div>
    <v-layout class="pb-2 overflow-hidden" style="flex: 1">
      <v-row class="fill-height overflow-hidden my-0">
        <v-col class="pb-0 pt-2 px-2 fill-height overflow-hidden" cols="12" lg="5" md="12">
          <v-row class="fill-height overflow-hidden ma-0">
            <v-col class="pa-0 pr-1 fill-height overflow-hidden" cols="12" md="6">
              <CardDataOfMonth
                :date.sync="currentDate"
                :label="'Select current date'"
                :times.sync="timeLines"
                @onChange="getStatistic"
              />
            </v-col>
            <v-col class="pa-0 pl-1 fill-height overflow-hidden" cols="12" md="6">
              <CardDataOfMonth
                :date.sync="compareDate"
                :label="'Select compare date'"
                :times.sync="availableCurrentDate"
                @onChange="getStatistic"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col class="pt-2 px-2 my-0 pb-0" cols="12" lg="7" md="12">
          <v-layout class="fill-height" style="position:relative">
            <CardMapView ref="cardMap" />
            <TourGuide
              style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
              ref="tourGuide"
              :color="'#e3e3e3'"
              :iconColor="'#66808d'"
              v-if="elements"
              :elements="elements"
            />
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout class="pt-2 fill-height overflow-hidden" style="flex: 1">
      <v-row class="fill-height overflow-hidden ma-0">
        <v-col ref="classificationChart" class="pa-0 pr-2 my-0 fill-height overflow-hidden" cols="12" md="5">
          <v-card class="custom-card-bg-1" height="100%">
            <v-card-text class="fill-height py-0 px-1" style="height: 100%">
              <v-layout class="fill-height" column>
                <div class="pa-3" style="width: 100%; height: fit-content; flex: none">
                  <v-layout align-center style="height: 100%">
                    <div class="subtitle-1">Data analysis</div>
                    <v-spacer />
                    <span v-if="Object.keys(service).length" class="subtitle-1">
                      -- {{ service.service_name.toUpperCase() + ' AREA SQKM' }} --
                    </span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ml-3"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          @click="ElemFullScreen.setFullscreenRef($refs.classificationChart)"
                        >
                          <v-icon size="18">{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-arrow-expand' }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Fullscreen</span>
                    </v-tooltip>
                  </v-layout>
                </div>
                <v-divider />
                <div v-if="!chartData.length" class="pa-3" style="width: 100%; height: 100%; border-radius: 8px; ">
                  <div style="width: 100%; height: 100%; border: 1px solid lightgray; border-radius: 8px; ">
                    <v-layout align-center class="fill-height" justify-center>
                      <h2>No data</h2>
                    </v-layout>
                  </div>
                </div>
                <v-row class="fill-height overflow-hidden my-0 pa-3">
                  <v-col class="fill-height overflow-hidden" cols="12" lg="8" md="12">
                    <v-layout class="fill-height">
                      <div v-show="chartData.length" class="chart-container" style="width: 100%; height: 100%;">
                        <canvas id="chart"></canvas>
                      </div>
                    </v-layout>
                  </v-col>
                  <v-col class="fill-height overflow-hidden" cols="12" lg="4" md="12">
                    <v-layout v-show="chartData.length" class="fill-height">
                      <div
                        id="legend-container"
                        style="width: 100%; height: 100%; overflow-y: auto; overflow-x: hidden; flex: none;"
                      >
                        <v-expansion-panels class="legend-panel" flat multiple>
                          <v-expansion-panel
                            v-for="(group, i) in groupLegend"
                            :key="i"
                            class="py-1 elevation-0"
                            style="border-radius: 0"
                          >
                            <v-expansion-panel-header
                              class="pa-1"
                              style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25); border-radius: 7px; max-height: 48px; height: 48px; min-height: 0"
                            >
                              <div
                                :style="{ color: group.legend[0].strokeStyle }"
                                style="width: 100%; height: 40px; font-size: 13px"
                              >
                                <v-layout align-center class="fill-height">
                                  <v-icon :color="group.legend[0].strokeStyle" class="mr-1" size="22">
                                    mdi-drag
                                  </v-icon>
                                  <v-checkbox
                                    v-model="group.checkAll"
                                    :color="group.legend[0].strokeStyle"
                                    class="small-radio"
                                    @click.native.stop="ChangeDisplayGroupLegend(group)"
                                  >
                                  </v-checkbox>
                                  {{ group.name }}
                                </v-layout>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                              class="px-0 custom-expansion-content"
                              style="background: #2f3241"
                            >
                              <v-list class="py-0" dense height="100%">
                                <v-list-item
                                  v-for="(label, index) of group.legend"
                                  :key="'legend-' + index"
                                  class="px-1"
                                  style="min-height: 0;height: 30px; background: #2f3241"
                                  @click="changeDisplayChart(label)"
                                >
                                  <v-list-item-action>
                                    <v-icon v-if="label.hidden" :color="label.fillStyle" size="22">
                                      mdi-checkbox-blank-outline
                                    </v-icon>
                                    <v-icon v-else :color="label.fillStyle" size="22">
                                      mdi-checkbox-marked
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span
                                        :style="{
                                          color: label.fillStyle,
                                          'text-decoration': label.hidden ? 'line-through' : '',
                                        }"
                                        class="text-truncate"
                                        style="font-size: 12px"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ label.text }}
                                      </span>
                                    </template>
                                    <span>{{ label.text }}</span>
                                  </v-tooltip>
                                  <v-spacer />
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="pa-0 pl-2 fill-height overflow-hidden" cols="12" md="7">
          <CardCompareChange :area.sync="area" :compare-date.sync="compareDate" :current-date.sync="currentDate" />
        </v-col>
      </v-row>
    </v-layout>
    <MakeOrder ref="makeOrder" isOnScreenDialog />
  </v-layout>
</template>

<script>
import CardDataOfMonth from '@/views/change-detection/CardData/CardDataOfMonth.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import CardCompareChange from '@/views/change-detection/CardData/CardCompareChange.vue'
import DashboardWeeklyOverview from '@/views/dashboard/DashboardWeeklyOverview.vue'
import sleep from '@/utils/sleep'
import { mapState } from '@/store/ults'
import Chart from 'chart.js/auto'
import { getOrder } from '@/api/order'
import { getChartData, getClassificationImages, getClassificationLayers } from '@/api/classification-api'
import Select2 from '@/components/Select2/Select2.vue'
import ElemFullScreen from '@/utils/ElemFullScreen'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import TourGuide from '@/components/GuideTour/index.vue'

export default {
  name: 'Classification',
  components: {
    Select2,
    DashboardWeeklyOverview,
    CardCompareChange,
    CardMapView,
    CardDataOfMonth,
    MakeOrder,
    TourGuide,
  },
  data() {
    return {
      currentData: {},
      isNotification: false,
      isFullscreen: false,
      currentAOI: undefined,
      service: {},
      source: undefined,
      loading: false,
      currentDate: {},
      compareDate: {},
      services: [],
      timeLines: [],
      chart: undefined,
      chartData: [],
      chartLegend: [],
      labels: [],
      data: [],
      elements: undefined,
    }
  },
  watch: {
    service(val) {
      if (val && Object.keys(val).length) {
        this.source = val.sources[0]
        this.getListData()
      } else this.source = undefined
    },
    async notificationId() {
      await this.initDataNoti()
    },
  },
  computed: {
    ElemFullScreen() {
      return ElemFullScreen
    },
    ...mapState('service', ['firstLoad']),
    ...mapState('AOI', ['AOIs', 'listAOI']),
    area() {
      if (this.currentAOI) return Number(this.currentAOI.area)
      else return 1
    },
    sources() {
      return this.service.sources
    },
    availableCurrentDate() {
      return this.timeLines.filter(val => JSON.stringify(val) !== JSON.stringify(this.currentDate))
    },
    groupLegend() {
      if (!this.chart) return []
      let groups = []
      this.chart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend],
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else
                groups.push({
                  name: dataset.stack,
                  stack: dataset.stack,
                  legend: [legend],
                })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    },
    notificationId() {
      return this.$route.query?.noti
    },
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  async mounted() {
    await this.initDataNoti()
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
    })
    this.elements = [
      {
        ref: this.$refs.AOI,
        id: 'AOI',
        title: 'Getting Started',
        text:
          this.listAOI.length > 0
            ? 'Select an AOI to get results'
            : "You don't have any AOIs with results. Please submit an order.",
        position: 'bottom',
        isHideContinueBtn: this.listAOI.length > 0 ? undefined : true,
        buttons:
          this.listAOI.length > 0
            ? undefined
            : [
                {
                  text: 'Submit Order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$refs.makeOrder.openDialog(null, 'Green Cover')
                  },
                },
              ],
      },
      {
        ref: this.$refs.source,
        id: 'source',
        title: 'Getting Started',
        text: 'Select a source of result',
        position: 'bottom',
      },
      {
        ref: this.$refs.service,
        id: 'service',
        title: 'Getting Started',
        text: this.data.length > 0 ? 'Select a service to show' : 'No result found, please check your order',
        position: 'bottom',
        buttons:
          this.data.length > 0
            ? undefined
            : [
                {
                  text: 'Check order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$router.push({
                      name: 'orders',
                      query: {
                        aoiId: this.currentAOI?.uuid,
                        service: this.services.map(service => service.service_id),
                        source: this.source,
                      },
                    })
                  },
                },
              ],
      },
    ]
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data?.order,
          aoi: noti.reference_data?.aoi,
          service: noti.reference_data?.service,
        }
        this.isNotification = true
      }
      await this.getListAOI()
    },
    async getService() {
      try {
        this.loading = true
        this.services = []
        this.resetData()
        this.$refs.cardMap.zoomTo(this.currentAOI.bbox)
        const res = await getOrder({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        this.services = res.data.filter(val => val.service_group === this.$route.meta.service)
        let tmpServices = []
        this.services.forEach(service => {
          let index = tmpServices.findIndex(val => val.service_name === service.service_name)
          if (index < 0) {
            service.sources = [service.image_source]
            tmpServices.push(service)
          } else tmpServices[index].sources.push(service.image_source)
        })
        this.services = []
        this.services = tmpServices
        //
        this.service = this.isNotification
          ? this.services.find(service => service.service_id == this.currentData.service.id)
          : this.services[0]
          ? this.services[0]
          : {}

        if (this.service) {
          this.source = this.service.sources[0]
          // await this.getListData()
        }
      } catch (e) {
        console.warn('error when fill service data', e)
      } finally {
        this.loading = false
      }
    },
    async getListData() {
      try {
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = []
        this.timeLines = []
        const res = await getClassificationImages({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.timeLines = res.data
        this.currentDate = res.data[0]
        this.compareDate = res.data[1] ? res.data[1] : {}
        // if (Object.keys(this.compareDate).length) await this.getStatistic()
        await this.getStatistic()
      } catch (e) {
      } finally {
      }
    },
    async getListAOI() {
      try {
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_group: this.$route.meta.service },
        })
        if (!this.isNotification) {
          this.currentAOI = this.AOIs[0]
        } else {
          this.currentAOI = this.AOIs.find(aoi => aoi.uuid == this.currentData.aoi.uuid)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getStatistic() {
      try {
        if (JSON.stringify(this.currentDate) === JSON.stringify(this.compareDate)) this.compareDate = {}
        if (!Object.keys(this.currentDate).length && !Object.keys(this.compareDate).length) return
        const res = await getChartData({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            source: this.source,
            date_range: [
              this.currentDate.acquired_date,
              this.compareDate.acquired_date ? this.compareDate.acquired_date : this.currentDate.acquired_date,
            ],
            service_id: this.service.service_id,
            sort: 'acquired_date',
          },
        })
        // let res = require('@/assets/mock/lancover.json')
        this.data = {}
        this.data = res.data
        await sleep(100)
        if (!this.chart) this.displayChart()
        else {
          this.transformData()
          this.updateChartData(this.chartData)
        }
        await this.displayLayers()
        await this.$refs.cardMap.reSize()
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async displayLayers() {
      this.$refs.cardMap.removeAllLayer()
      let display = false
      try {
        const res = await getClassificationLayers({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            source: this.source,
            date_range: [
              this.currentDate.acquired_date,
              this.compareDate.acquired_date ? this.compareDate.acquired_date : this.currentDate.acquired_date,
            ],
            service_id: this.service.service_id,
            sort: '-acquired_date',
          },
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) display = true
          val.tile_info.name = val.name
          if (val.tile_info.type === 'images') this.$refs.cardMap.addRasterToMap(val.tile_info, display)
          // else this.$refs.cardMap.addVectorToMap(val.tile_info, display)
        })
      } catch (e) {
      } finally {
      }
    },
    transformData() {
      this.chartData = []
      this.labels = []
      this.labels = this.data.map(val => val.date)
      this.data.forEach(val => {
        val.stats.forEach(index => {
          if (!this.chartData.some(el => el.label === index.label)) {
            this.chartData.push({
              label: index.label,
              data: [index.area / 1000000],
              type: 'bar',
              barPercentage: 0.8,
              borderColor: [index.color],
              backgroundColor: [index.color],
              borderWidth: 1,
              stack: this.service.service_name,
            })
          } else {
            this.chartData.find(data => data.label === index.label).data.push(index.area / 1000000)
          }
        })
      })
    },
    displayChart() {
      if (!this.data) return
      this.transformData()
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById('chart')
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart)
        },
      }
      this.chart = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top',
            },
          },
          pointBorderColor: '#0022dc',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: true,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
            y: {
              ticks: {
                color: '#d4d4d4',
              },
              // title: {
              //   color: '#d4d4d4',
              //   text: this.service.service_name.toUpperCase() + ' AREA SQKM',
              //   display: true,
              // },
              stacked: true,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
        plugins: [htmlLegendPlugin],
      })
      if (this.tab === 1) this.hiddenChart()
    },
    updateChartData(newData) {
      this.chart.data.datasets = newData
      this.chart.data.labels = this.labels
      this.chart.update()
    },
    ChangeDisplayGroupLegend(group) {
      group.legend.forEach(val => {
        val.hidden = group.checkAll
        this.changeDisplayChart(val)
      })
    },
    changeDisplayChart(legend) {
      this.chart.setDatasetVisibility(legend.datasetIndex, legend.hidden)
      this.chart.update()
    },
    resetData() {
      this.timeLines = []
      this.chartData = []
      this.data = {}
      this.currentDate = {}
      this.compareDate = {}
    },
  },
}
</script>

<style scoped>
.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.v-expansion-panels--flat > .v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
